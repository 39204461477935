import { render, staticRenderFns } from "./contentindex.vue?vue&type=template&id=2b9ca951&scoped=true&"
import script from "./contentindex.vue?vue&type=script&lang=js&"
export * from "./contentindex.vue?vue&type=script&lang=js&"
import style0 from "./contentindex.vue?vue&type=style&index=0&id=2b9ca951&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9ca951",
  null
  
)

export default component.exports