<template>
    <LiefengContent>
        <template v-slot:title>内容管理</template>
        <template v-slot:toolsbarRight>
            <Form :model="searchData" :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input v-model.trim="searchData.pointName" placeholder="地名名称" style="width: 160px"></Input>
                </FormItem>
                <FormItem>
                    <Select transfer style="margin-right: 10px; width: 150px" v-model="searchData.catId">
                        <Option :value="item.catId" v-for="(item, index) in typeList" :key="index">{{ item.catName }}</Option>
                    </Select>
                </FormItem>
                <FormItem v-if="!$route.query.scenicId && !$route.query.orgCode">
                    <CascaderCity @changeCasader="changeCasader" :resetNum="resetNum" :orgCode="4401" :width="200"></CascaderCity>
                </FormItem>
                <Button type="primary" icon="ios-search" @click="searchBtn" style="margin-right: 10px">查询</Button>
                <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button>
            </Form>
        </template>
        <template v-slot:toolsbarLeft>
            <Button type="info" @click="append({})" v-if="$route.query.scenicId && $route.query.orgCode">新增</Button>
            <Button type="info" @click="append({})" v-if="!$route.query.scenicId && !$route.query.orgCode && buttonRoot.includes('1001')">批量新增</Button>
            <!-- <Button type="info" @click="jumpType()">类型管理</Button> -->
        </template>
        <template v-slot:contentArea>
            <div class="table-left">
                <Menu theme="light" :active-name="activeMenu" @on-select="activeMenu = $event">
                    <MenuGroup title="内容状态">
                        <MenuItem name="1" v-if="buttonRoot.includes('1002')">待审核 ( {{ quantity_1 }} )</MenuItem>
                        <MenuItem name="2" v-if="buttonRoot.includes('1001')">待修改 ( {{ quantity_2 }} )</MenuItem>
                        <MenuItem name="3" v-if="buttonRoot.includes('1003')">待发布 ( {{ quantity_3 }} )</MenuItem>
                        <MenuItem name="4">已发布</MenuItem>
                        <MenuItem name="0">全部信息</MenuItem>
                    </MenuGroup>
                </Menu>
            </div>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                height="200"
                :loading="loading"
                :fixTable="true"
                :pagesizeOpts="[20, 30, 50, 100]"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
            <LiefengModal :title="currentFormData.pointId ? '修改' : '新增'" width="80%" height="700px" :value="addModal.status" @input="addModalData" class="export-modal" style="max-width: 1000px">
                <template v-slot:contentarea>
                    <Form :model="currentFormData" :disabled="!buttonRoot.includes('1001')" :label-width="120" :rules="validateForm" class="form" ref="form">
                        <FormItem label="名称" prop="pointName">
                            <Input v-model.trim="currentFormData.pointName" placeholder="请输入名称" maxlength="50"></Input>
                        </FormItem>
                        <FormItem label="地址">
                            <Input @on-change="onInput" @on-focus="onInputFocus" @on-blur="onInputBlur" v-model.trim="currentFormData.pointAddr" placeholder="请输入地址"></Input>
                        </FormItem>
                        <FormItem label="百度经纬度">
                            <Input placeholder="百度经纬度如：113.273441,23.145438" v-model.trim="currentFormData.lgt" :maxlength="40"></Input>
                        </FormItem>
                        <FormItem width="120px" label="选择经纬度">
                            <Button type="info" @click="openMap">选择经纬度</Button>
                        </FormItem>
                        <!-- <FormItem>
                            <span slot="label">经纬度:</span>
                            <ElemMap
                                :address="currentFormData.pointAddr"
                                :location="{ longitude: currentFormData.lgt, latitude: currentFormData.lat }"
                                @on-change="
                                    evt => {
                                        currentFormData.pointAddr = evt.value.address
                                        currentFormData.lgt = evt.value.location.longitude
                                        currentFormData.lat = evt.value.location.latitude
                                    }
                                "
                                ref="elemMap"
                            ></ElemMap>
                        </FormItem> -->
                        <FormItem>
                            <span slot="label" class="validate">类型:</span>
                            <CheckboxGroup v-model="currentFormData.catIdList">
                                <Checkbox v-for="(item, index) in typeList" :key="index" :label="item.catId">
                                    {{ item.catName }}
                                </Checkbox>
                            </CheckboxGroup>
                        </FormItem>
                        <FormItem v-if="($route.query.scenicName && $route.query.scenicName != '') || scenicName">
                            <span slot="label" class="validate">所属村居:</span>
                            <span>{{ $route.query.scenicName || scenicName }}</span>
                        </FormItem>
                        <FormItem v-if="!$route.query.scenicId && !$route.query.orgCode && !currentFormData.pointId">
                            <span slot="label" class="validate">所属村居:</span>
                            <LiefengCascader v-if="showCasader" ref="LiefengCascader" :dataScopeList="dataScopeList"></LiefengCascader>
                        </FormItem>
                        <FormItem label="联系电话">
                            <Input v-model.trim="currentFormData.pointMobile" placeholder="请填写电话"></Input>
                        </FormItem>
                        <FormItem label="收费">
                            <Input v-model.trim="currentFormData.fee" placeholder="如：1元/时"></Input>
                        </FormItem>
                        <FormItem label="描述">
                            <iframe
                                name="ueditor"
                                id="ueditor"
                                src="/mobileeditor/#/index?titlename=描述"
                                style="width: 100%; height: 500px; border: 0px"
                                @load="loadUeditor"
                                v-if="addModal.status"
                            ></iframe>
                            <!-- <Input type="textarea" v-model.trim="currentFormData.pointDesc" placeholder="请输入50字内描述"></Input> -->
                        </FormItem>
                        <FormItem label="现场图">
                            <LiefengUpload
                                ref="LiefengUploadIcon"
                                v-if="showImage"
                                accept=".jpg,.png,.gif,.jpeg"
                                :format="['jpg', 'png', 'jpeg', 'gif']"
                                :defaultList="currentFormData.pointImage"
                                :showView="true"
                                :fileLength="1000"
                                :target="'image'"
                                @success="uploadImageSuccess"
                            ></LiefengUpload>
                            <div>
                                <Input v-model="imageUrl" search @on-search="pushUrlBtn" enter-button="添加图片链接" placeholder="请输入链接地址" />
                            </div>
                        </FormItem>
                        <FormItem label="语音文件">
                            <LiefengUploadAudio :showDownBtn="true" v-if="showAudio" ref="LiefengUploadAudio" @success="uploadAudio"></LiefengUploadAudio>
                        </FormItem>
                        <FormItem label="视频文件">
                            <LiefengUploadVideo
                                ref="videoData"
                                v-if="showVideo"
                                :defaultList="currentFormData.pointJson.videoUrl"
                                :target="'video'"
                                @success="
                                    (target, val) => {
                                        uploadVideoSuccess(target, val, index)
                                    }
                                "
                                :showView="true"
                                :fileLength="1000"
                            ></LiefengUploadVideo>
                        </FormItem>

                        <FormItem label="直播">
                            <Input v-model.trim="currentFormData.pointJson.liveId" placeholder="直播间号"></Input>
                        </FormItem>
                        <FormItem label="商家ID">
                            <Input v-model.trim="currentFormData.pointJson.shopId" placeholder="填写商家ID"></Input>
                        </FormItem>
                        <FormItem label="商品ID">
                            <Input v-model.trim="currentFormData.pointJson.shopsId" placeholder="填写商品ID"></Input>
                        </FormItem>
                    </Form>

                    <div class="operator-box" v-if="currentFormData.operatorVo">
                        <div class="item-box">审核状态：{{ ["待审核", "驳回", "待发布", "已发布"][currentFormData.operatorVo.status - 1] || "待审核" }}</div>
                        <div class="item-box" v-if="currentFormData.operatorVo.status === 2">驳回原因：{{ currentFormData.operatorVo.reason }}</div>
                        <div class="item-box" v-if="currentFormData.operatorVo.status >= 3 && currentFormData.operatorVo.reviewName">审核人：{{ currentFormData.operatorVo.reviewName }}</div>
                        <div class="item-box" v-if="currentFormData.operatorVo.status >= 3 && currentFormData.operatorVo.reviewTime">
                            审核时间：{{ $core.formatDate(new Date(currentFormData.operatorVo.reviewTime), "yyyy-MM-dd hh:mm") }}
                        </div>
                        <div class="item-box" v-if="currentFormData.operatorVo.status >= 4 && currentFormData.operatorVo.publishName">发布人：{{ currentFormData.operatorVo.publishName }}</div>
                        <div class="item-box" v-if="currentFormData.operatorVo.status >= 4 && currentFormData.operatorVo.publishTime">
                            发布时间：{{ $core.formatDate(new Date(currentFormData.operatorVo.publishTime), "yyyy-MM-dd hh:mm") }}
                        </div>
                    </div>
                </template>
                <template #toolsbar>
                    <block v-if="buttonRoot.includes('1002')">
                        <Button type="primary" style="margin-right: 10px" @click="onChangeStatus(3)">审核通过</Button>
                        <Button type="error" @click="refuse.status = true">驳回</Button>
                    </block>
                    <block v-else-if="buttonRoot.includes('1003')">
                        <Button type="primary" style="margin-right: 10px" @click="onChangeStatus(4)">发布</Button>
                        <Button type="error" @click="refuse.status = true">驳回</Button>
                    </block>
                    <block v-else>
                        <Button type="primary" style="margin-right: 10px" icon="ios-share-outline" @click="submit()">保存</Button>
                        <Button type="info" @click="addModalData(false)">取消</Button>
                    </block>
                </template>
            </LiefengModal>

            <LiefengModal title="驳回" width="500px" height="260px" :value="refuse.status" @input="refuse.status = $event" class="export-modal">
                <template v-slot:contentarea>
                    <Form :model="currentFormData" :label-width="75" :rules="validateForm" class="form" ref="form">
                        <FormItem label="驳回原因" prop="refuse">
                            <Input type="textarea" v-model.trim="refuse.cause" placeholder="请输入驳回原因" maxlength="250" :rows="8"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button type="primary" style="margin-right: 10px" icon="ios-share-outline" @click="onChangeStatus(2, refuse.cause)">提交</Button>
                    <Button type="info" @click="refuse = { status: false }">取消</Button>
                </template>
            </LiefengModal>

            <LiefengModal class="map" :fullscreen="false" width="80%" title="选择经纬度" @input="mapFn" :value="mapStatus">
                    <template #contentarea style="width: 100%; height: 700px">
                        <Map
                            :showNum="showNum"
                            :localName="localName"
                            :center="center"
                            @clickMap="
                                evt => {
                                    currentFormData.lgt = evt.lng + ',' + evt.lat
                                }
                            "
                        ></Map>
                    </template>
                    <template #toolsbar>
                        <Button
                            type="info"
                            style="margin-right: 10px"
                            @click="
                                mapTip = true
                                mapFn(false)
                            "
                        >
                            取消
                        </Button>
                        <Button type="primary" @click="saveMap">确定</Button>
                    </template>
                </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUpload from "@/components/LiefengUpload"
import ElemMap from "@/views/jointly/components/ElemMap.vue"
import LiefengUploadAudio from "@/components/LiefengUploadAudio"
import LiefengUploadVideo from "@/components/LiefengUploadVideo"
import CascaderCity from "@/components/LiefengCascaderCity"
import LiefengCascader from "@/components/LiefengCascader"
import Map from "../jujiabuild/Institutionalmanagement/childrens/map"
export default {
    components: { LiefengTable, LiefengContent, LiefengModal, LiefengUpload, ElemMap, LiefengUploadAudio, LiefengUploadVideo, CascaderCity, LiefengCascader,Map },
    data() {
        const buttonRoot = parent.vue.userButtonRoot
        var status = ""

        if (buttonRoot.includes("1002")) status = 1
        else if (buttonRoot.includes("1001")) status = 2
        else if (buttonRoot.includes("1003")) status = 3

        return {
            // 当前菜单
            activeMenu: String(status),
            // 权限
            buttonRoot: buttonRoot,
            // 当前状态
            status: status,
            // 待审核数量
            quantity_1: 0,
            // 待修改数量
            quantity_2: 0,
            // 待发布数量
            quantity_3: 0,
            // 驳回对象
            refuse: {
                status: false,
            },
            talbeColumns: [
                {
                    title: "地名名称",
                    align: "center",
                    minWidth: 180,
                    key: "pointName",
                },
                {
                    title: "地址",
                    align: "center",
                    minWidth: 180,
                    key: "pointAddr",
                },

                {
                    title: "所属村居",
                    align: "center",
                    minWidth: 180,
                    key: "scenicName",
                    // render: (h, params) => {
                    //     return h("div", {}, this.$route.query.scenicName)
                    // },
                },
                {
                    title: "地名类型",
                    align: "center",
                    minWidth: 180,
                    key: "catName",
                },
                {
                    title: "审核状态",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "span",
                            {
                                style: {
                                    color: params.row.status == 4 ? "#00B050" : "#FF0000",
                                },
                            },
                            (params.row.status ? ["待审核", "驳回", "待发布", "已发布"][params.row.status - 1] : "") || "待审核"
                        )
                    },
                },
                {
                    title: "是否显示",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("i-switch", {
                            props: {
                                value: params.row.enable == 1,
                            },
                            on: {
                                "on-change": value => {
                                    this.$Message.loading({
                                        content: "正在操作，请稍等",
                                    })
                                    this.$get("/gateway/api/syscenic/pc/point/getByPointId", {
                                        pointId: params.row.pointId,
                                    }).then(res => {
                                        this.$Message.destroy()
                                        if (res && res.code == 200) {
                                            this.currentFormData = {
                                                ...res.data,
                                                enable: value ? 1 : 0,
                                            }
                                            this.submit(true, params.row.status)
                                        } else {
                                            this.$Message.error({
                                                content: "获取数据失败",
                                                background: true,
                                            })
                                            return
                                        }
                                    })
                                    // this.currentFormData = {
                                    //     pointId: params.row.pointId, //景点id
                                    //     scenicId: params.row.scenicId, //景区id
                                    //     pointName: params.row.pointName, //景点名称
                                    //     pointDesc: params.row.pointDesc, //景点备注
                                    //     pointIcon: params.row.pointIcon, //景点图标
                                    //     pointImage: params.row.pointImage, //景点图片
                                    //     pointSort: params.row.pointSort, //景点排序
                                    //     pointAudio: params.row.pointAudio, //景点音频介绍
                                    //     lat: params.row.lat, //纬度
                                    //     lgt: params.row.lgt, //经度
                                    //     pointAddr: params.row.pointAddr, //景点地址
                                    //     pointMobile: params.row.pointMobile, //景点联系电话
                                    //     fee: params.row.fee, //费用
                                    //     orgCode: params.row.orgCode, //所属社区
                                    //     enable: value ? 1 : 0,
                                    //     creatorId: params.row.creatorId,
                                    //     creatorAccount: params.row.creatorAccount,
                                    //     catIdList: params.row.catIdList,
                                    //     pointJson: params.row.pointJson,
                                    // }
                                    // this.submit(true)
                                },
                            },
                        })
                    },
                },
                {
                    title: "添加账号",
                    align: "center",
                    minWidth: 180,
                    key: "creatorAccount",
                },
                {
                    title: "添加时间",
                    align: "center",
                    minWidth: 180,
                    key: "gmtCreate",
                    render: (h, params) => {
                        return h("span", {}, params.row.gmtCreate && params.row.gmtCreate != "" ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    width: 230,
                    fixed: "right",
                    render: (h, params) => {
                        const arr = []
                        // 判断审核权限
                        if (((params.row.status === 1 || !params.row.status) && this.buttonRoot.includes("1002")) || (params.row.status === 3 && this.buttonRoot.includes("1003"))) {
                            arr.push(
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.scenicName = params.row.scenicName
                                                this.getDetail(params.row.pointId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    params.row.status === 3 ? "发布" : "审核"
                                )
                            )
                        } else if (this.buttonRoot.includes("1001")) {
                            arr.push(
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.scenicName = params.row.scenicName
                                                this.getDetail(params.row.pointId)
                                                return
                                                this.currentFormData = {
                                                    pointId: params.row.pointId,
                                                    scenicId: params.row.scenicId, //景区id
                                                    pointName: params.row.pointName, //景点名称
                                                    pointDesc: params.row.pointDesc, //景点备注
                                                    pointIcon: params.row.pointIcon, //景点图标
                                                    pointImage: params.row.pointImage, //景点图片
                                                    pointSort: params.row.pointSort, //景点排序
                                                    pointAudio: params.row.pointAudio, //景点音频介绍
                                                    lat: params.row.lat, //纬度
                                                    lgt: params.row.lgt, //经度
                                                    pointAddr: params.row.pointAddr, //景点地址
                                                    pointMobile: params.row.pointMobile, //景点联系电话
                                                    fee: params.row.fee, //费用
                                                    orgCode: params.row.orgCode, //所属社区
                                                    // catId: params.row.catId,
                                                    enable: params.row.enable, //是否启用
                                                    creatorId: params.row.creatorId, //创建人id
                                                    creatorAccount: params.row.creatorAccount, //创建人账号
                                                }
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.deleteData(params.row.pointId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "删除" //1001
                                )
                            )
                        }

                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                ...arr,
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: "访问纪录",
                                                    content: `/page#/convenientrecord?scenicId=${params.row.scenicId}&pointId=${params.row.pointId}`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "访问记录" //1001
                                ),
                                // h(
                                //     "span",
                                //     {
                                //         on: {
                                //             click: async () => {
                                //                 this.$core.openLayerFrame({
                                //                     type: 2,
                                //                     title: "访问纪录",
                                //                     content: `/page#/convenientrecord?scenicId=${params.row.scenicId}&pointId=${params.row.pointId}`,
                                //                     area: ["100%", "100%"],
                                //                 })
                                //             },
                                //         },
                                //         style: {
                                //             width: "65px",
                                //             marginRight: "10px",
                                //             textAlign: "center",
                                //             color: "#2d8cf0",
                                //             cursor: "pointer",
                                //             userSelect: "none",
                                //         },
                                //     },
                                //     "审核记录"
                                // ),
                            ]
                        )
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,

            currentFormData: {},
            validateForm: {
                pointName: [{ required: true, message: "请输入名称", trigger: "blur" }],
            },

            addModal: {
                status: false,
            },
            showImage: false,
            showAudio: false,
            showVideo: false,

            typeList: [],

            // 图片链接地址
            imageUrl: "",
            resetNum: 1,

            searchData: {
                pointName: "",
                orgCode: "",
                catId: "",
            },
            searchList: [],

            scenicName: "",

            dataScopeList: ["44-4401"],

            addOrgCode: "",
            showCasader: false,

            // 地图模态框
            mapStatus: false,
            mapTip: false,
            showNum: 0,
            localName: "",
            center: {
                lat: "",
                lng: "",
            },
            clickCenter: {
                lat: "",
                lng: "",
            },
        }
    },

    watch: {
        activeMenu(v) {
            this.status = v === "0" ? "" : Number(v)
            this.getList()
        },
    },

    async created() {
        sessionStorage.setItem("NODEENV", process.env.NODE_ENV)
        await this.getType()
        await this.getList()
    },
    methods: {
         // 关闭模态框
        mapFn(status) {
            if (!status && this.mapTip) {
                this.mapStatus = status
                this.mapTip = false
            } else {
                this.mapStatus = status
            }
        },
        // 选择经纬度确定按钮
        saveMap() {
            this.mapStatus = false
        },
        openMap() {
            this.mapStatus = true
        },
        changeCasader(val) {
            this.searchList = val
        },
        searchBtn() {
            if (this.searchList.length && this.searchList.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区再进行筛选",
                    background: true,
                })
                return
            }
            this.searchData.orgCode = this.searchList[this.searchList.length - 1]
            this.page = 1
            this.getList()
        },
        resetBtn() {
            this.searchList = []
            this.searchData = {
                pointName: "",
                orgCode: "",
                catId: "",
            }
            ++this.resetNum
            this.page = 1
            this.getList()
        },
        uploadVideoSuccess(val, file, index) {
            this.currentFormData.pointJson.videoUrl = this.$refs.videoData.uploadList
            // console.log(this.currentFormData)
            // if (file && file.url) this.currentFormData.pointJson.videoUrl.push({ name: "", url: file.url })
            // else this.currentFormData.pointJson.videoUrl = []
        },
        uploadImageSuccess(val, file) {
            console.log("上传成功之后触发")
            this.currentFormData.pointImage = this.$refs.LiefengUploadIcon.uploadList
        },
        isImageUrl(url) {
            const regex = /^https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*/
            return regex.test(url)
        },
        pushUrlBtn() {
            if (!this.imageUrl || this.imageUrl == "") {
                this.$Message.warning({
                    content: "请输入链接地址",
                    background: true,
                })
                return
            }
            if (!this.isImageUrl(this.imageUrl)) {
                this.$Message.warning({
                    content: "请输入正确的图片链接",
                    background: true,
                })
                return
            }
            this.currentFormData.pointImage.push({
                url: this.imageUrl,
                name: "",
                status: "finished",
            })
            this.imageUrl = ""
            this.showImage = false
            this.$nextTick(() => {
                this.showImage = true
            })
        },
        onInput(evt) {
            this.$refs.elemMap.onInput(evt)
        },
        onInputFocus() {
            this.$refs.elemMap.onInputFocus()
        },
        onInputBlur() {
            this.$refs.elemMap.onInputBlur()
        },
        loadUeditor() {
            if (this.currentFormData.pointDesc != "" && this.currentFormData.pointDesc != null && this.currentFormData.pointDesc != undefined) {
                if (document.getElementById("ueditor")) {
                    var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
                    v_Obj.setContent(this.currentFormData.pointDesc) //写入编辑器富文本内容
                }
            } else {
                // setTimeout(this.loadUeditor, 300);
            }
        },
        // 音频上传成功
        uploadAudio(val) {
            if (val && val.length) {
                this.currentFormData.pointAudio = val[0].url
            } else {
                this.currentFormData.pointAudio = ""
            }
        },
        getDetail(pointId) {
            this.$get("/gateway/api/syscenic/pc/point/getByPointId", {
                pointId,
            }).then(res => {
                if (res.data && res.code == 200) {
                    this.addModalData(true)
                    var cFormData = res.data
                    if (cFormData.pointImage && cFormData.pointImage != "") {
                        var list = []
                        cFormData.pointImage.split(",").map(item => {
                            list.push({ url: item, name: "" })
                        })
                        cFormData.pointImage = list
                    } else {
                        cFormData.pointImage = []
                    }
                    if (!cFormData.catIdList || cFormData.catIdList == "") cFormData.catIdList = []
                    if (cFormData.pointJson && cFormData.pointJson != "") {
                        cFormData.pointJson = JSON.parse(cFormData.pointJson)
                        if (cFormData.pointJson.videoUrl && cFormData.pointJson.videoUrl != "") {
                            let list = []
                            cFormData.pointJson.videoUrl.split(",").map(item => {
                                list.push({
                                    name: "",
                                    url: item,
                                })
                            })
                            cFormData.pointJson.videoUrl = list
                        }
                    } else {
                        cFormData.pointJson = {
                            videoUrl: [],
                            liveId: "",
                            shopId: "",
                            shopsId: "",
                        }
                    }

                    if(cFormData.lgt && cFormData.lgt != '' && cFormData.lat && cFormData.lat != ''){
                        cFormData.lgt = cFormData.lgt + ',' + cFormData.lat
                    }

                    this.currentFormData.pointAddr = ""
                    this.currentFormData = cFormData

                    this.showImage = false
                    this.showAudio = false
                    this.showVideo = false
                    this.$nextTick(() => {
                        this.showImage = true
                        this.showAudio = true
                        this.showVideo = true
                        if (cFormData.pointAudio && cFormData.pointAudio != "") {
                            this.$nextTick(() => {
                                this.$refs.LiefengUploadAudio.uploadArr = [
                                    {
                                        name: cFormData.pointAudio.split("/")[cFormData.pointAudio.split("/").length - 1],
                                        url: cFormData.pointAudio,
                                    },
                                ]
                            })
                        } else {
                            this.$refs.LiefengUploadAudio.uploadArr = []
                        }
                        var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
                        v_Obj.setContent(this.currentFormData.pointDesc) //写入编辑器富文本内容
                    })
                    this.$forceUpdate()
                } else {
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 跳转类型管理
        jumpType(scenicId) {
            this.$core.openLayerFrame({
                type: 2,
                title: "类型管理",
                content: `/page#/placenametypeindex?scenicId=${scenicId}`,
                area: ["100%", "100%"],
            })
        },
        append() {
            this.currentFormData = {
                scenicId: this.$route.query.scenicId, //景区id
                pointName: "", //景点名称
                pointDesc: "", //景点备注
                pointIcon: "", //景点图标
                pointImage: [], //景点图片
                pointSort: 1, //景点排序
                pointAudio: "", //景点音频介绍
                lat: "", //纬度
                lgt: "", //经度
                pointAddr: "", //景点地址
                pointMobile: "", //景点联系电话
                fee: "", //费用
                orgCode: this.$route.query.orgCode, //所属社区
                catIdList: [],
                enable: 1, //是否启用
                creatorId: parent.vue.loginInfo.userinfo.custGlobalId,
                creatorAccount: parent.vue.loginInfo.userinfo.mobile,
                pointJson: {
                    videoUrl: [],
                    liveId: "",
                    shopId: "",
                    shopsId: "",
                },
                parentId: this.$route.query.parentId,
            }
            this.addOrgCode = ""
            this.showImage = false
            this.showAudio = false
            this.showVideo = false
            this.showCasader = false
            this.$nextTick(() => {
                this.showImage = true
                this.showVideo = true
                this.showCasader = true
                this.showAudio = true
                this.$refs.LiefengUploadAudio.uploadArr = []
                // this.$refs.videoData.uploadArr = []
            })
            this.addModalData(true)
        },
        deleteData(id) {
            this.$Modal.confirm({
                title: "删除选项",
                content: "删除后无法恢复，是否继续删除？",
                okText: "确认删除",
                cancelText: "取消",
                onOk: () => {
                    this.$post("/gateway/api/syscenic/pc/point/deleteById", {
                        pointId: id,
                    }).then(res => {
                        var that = this
                        if (res && res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            that.getList()
                            return
                        }
                    })
                },
            })
        },
        submit(enable, status) {
            if (enable && enable != "") {
                this.submitPost(status)
                return
            }
            this.currentFormData.pointImage = (() => {
                if (this.$refs.LiefengUploadIcon.uploadList && this.$refs.LiefengUploadIcon.uploadList.length) {
                    let list = []
                    this.$refs.LiefengUploadIcon.uploadList.map(item => {
                        list.push(item.url)
                    })
                    return list && list.length ? list.join(",") : ""
                } else {
                    return ""
                }
            })()
            var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
            this.currentFormData.pointDesc = v_Obj.getContent() //获取富文本内容
            if (!this.currentFormData.pointAddr || this.currentFormData.pointAddr == "") {
                this.currentFormData.pointAddr = ""
                this.currentFormData.lgt = ""
                this.currentFormData.lat = ""
            }
            this.$refs.form.validate(status => {
                if (status) {
                    if (!this.currentFormData.catIdList || !this.currentFormData.catIdList.length) {
                        this.$Message.error({
                            background: true,
                            content: "请选择类型",
                        })
                        return
                    }
                    if (!this.$route.query.scenicId && !this.$route.query.orgCode && !this.currentFormData.pointId) {
                        var list = this.$refs.LiefengCascader.exportCode()
                        if (!list || !list.length) {
                            this.$Message.error({
                                background: true,
                                content: "请选择所属村居",
                            })
                            return
                        }
                        if (list && list.length) {
                            var list2 = list.map(item => {
                                return [item.split("-")]
                            })
                            var list3 = []
                            list2.map(item => {
                                item.map(items => {
                                    items.map((itemss, indexss) => {
                                        if (indexss == items.length - 1) {
                                            list3.push(itemss)
                                        }
                                    })
                                })
                            })
                            this.addOrgCode = list3.join(",")
                        }
                    }

                    this.currentFormData.pointJson.videoUrl = (() => {
                        if (this.currentFormData.pointJson.videoUrl && this.currentFormData.pointJson.videoUrl.length) {
                            let list = []
                            this.currentFormData.pointJson.videoUrl.map(item => {
                                list.push(item.url)
                            })
                            return list && list.length ? list.join(",") : ""
                        } else {
                            return ""
                        }
                    })()

                    if (this.currentFormData.pointJson && JSON.stringify(this.currentFormData.pointJson) != "{}") {
                        this.currentFormData.pointJson = JSON.stringify(this.currentFormData.pointJson)
                    }
                    this.submitPost()
                }

                return
            })
        },
        submitPost(status) {
            var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
            if (this.addOrgCode && this.addOrgCode != "") cFormData.orgCode = this.addOrgCode
            // cFormData.catIdList = Array.isArray(cFormData.catIdList) ? cFormData.catIdList.join(",") : cFormData.catIdList
            var url = ""
            if (cFormData.pointId && cFormData.pointId != "") {
                url = "/gateway/api/syscenic/pc/point/updateScenicPoint"
            } else {
                url = "/gateway/api/syscenic/pc/point/addScenicPoint"
            }

            if(this.currentFormData.lgt && this.currentFormData.lgt != '' && this.currentFormData.lgt.indexOf(',') > -1){
                let list = this.currentFormData.lgt.split(',')
                cFormData.lgt = list[0]
                cFormData.lat = list[1]
            }

            this.$post(
                url,
                {
                    ...cFormData,
                    status: status && status != "" ? status : "1",
                },
                { "content-type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.addModalData(false)
                        this.$Message.success({
                            background: true,
                            content: "操作成功",
                        })
                        this.currentFormData = {}
                        this.getList(this.page)
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })

                        if (this.currentFormData.pointJson && this.currentFormData.pointJson != "") {
                            this.currentFormData.pointJson = JSON.parse(this.currentFormData.pointJson)
                            if (this.currentFormData.pointJson.videoUrl && this.currentFormData.pointJson.videoUrl != "") {
                                let list = []
                                this.currentFormData.pointJson.videoUrl.split(",").map(item => {
                                    list.push({
                                        name: "",
                                        url: item,
                                    })
                                })
                                this.currentFormData.pointJson.videoUrl = list
                            }
                        } else {
                            this.currentFormData.pointJson = {
                                videoUrl: [],
                                liveId: "",
                                shopId: "",
                                shopsId: "",
                            }
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "保存失败",
                    })
                })
        },
        addModalData(status) {
            if (!status) {
                // this.showVideo = false
                this.currentFormData.pointJson = {
                    videoUrl: [],
                    liveId: "",
                    shopId: "",
                    shopsId: "",
                }
            }
            this.addModal.status = status
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 接口部分
        async getList() {
            this.loading = true
            await this.$get("/gateway/api/syscenic/pc/point/listScenicPointByPage", {
                page: this.page,
                pageSize: this.pageSize,
                scenicId: this.$route.query.scenicId,
                orgCode: this.$route.query.orgCode || this.searchData.orgCode || "",
                parentId: this.$route.query.scenicId && this.$route.query.orgCode ? "" : this.$route.query.parentId,
                pointName: this.searchData.pointName || "",
                catId: this.searchData.catId,
                status: this.status,
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    this.loading = false
                    this.tableData = res.dataList.map(item => {
                        return {
                            ...item,
                        }
                    })
                }
                this.total = res.maxCount

                if ([1, 2, 3].includes(this.status)) {
                    this["quantity_" + this.status] = res.maxCount
                }
            })
        },
        async getType() {
            await this.$get("/gateway/api/syscenic/pc/cat/selectByScenic", {
                scenicId: this.$route.query.parentId || "",
                catType: "1",
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    this.typeList = res.dataList
                }
            })
        },

        onChangeStatus(status, reason) {
            this.$post("/gateway/api/syscenic/pc/point/changePoint", {
                pointId: this.currentFormData.pointId,
                status: status,
                reason: reason || "",
            })
                .then(res => {
                    if (res.code !== "200") {
                        return this.$Message.error(res.desc || res.msg || "请求失败")
                    }
                    this.addModalData(false)
                    this.refuse = {
                        status: false,
                    }
                    this.$Message.success("操作成功")
                    this.currentFormData = {}
                    this.getList(this.page)
                })
                .catch(err => {
                    this.$Message.error(err.response?.data?.error || "请求失败")
                })
        },
    },
}
</script>

<style scoped lang="less">
.table-left {
    float: left;
    width: 180px;
    /deep/.ivu-menu {
        width: 180px !important;
    }
}

/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}

.operator-box {
    padding: 15px 20px;
    margin-top: 30px;
    border-top: 1px solid #e8eaec;

    .item-box {
        line-height: 30px;
    }
}
</style>
